export default {
  title: "Tournaments",
  description:
    "ITU LAN is hosting several tournaments and events during the weekend, and here you can see an overview over the tournaments and event. Are you up for the challenge?",
  notPublic: "Tournaments to be announced...",
  clickAndRegister: "Click and register",
  by: "powered by",
  tournamentCards: {
    descriptionTBA: "Description TBA",
    platformLabel: "Platform: ",
    tournaments: [
      {
      title: "League of legends 13:00 Sat",
      toornUrl:  "https://play.toornament.com/tournaments/8183026150309158912/",
      platform: " PC ",
      description:"We're finally back with a League of Legends tournament for the ITU LAN F'24!</br> Gather your 5 player team, and get ready to bask in the glory reflected in the salty tears of your opponents.</br>The tournament will be open for up to 12 teams, which must be present physically at ITU LAN F'24 with valid tickets.</br>First stage of the tournament is scheduled to begin at 13:00 / 1PM on Saturday, November the 16th.</br>Check-in at 12:00 / Noon, after which we settle on the final tournament structure, based on the amount of teams present Checkin is a message from one discord in the tournament channel.</br>Registration is on a first-come-first-served principle.</br>Sign up early to secure your spot!</br>  Remember to read the rules if you sign up!",
      logoPath: ""
      },
      {
        title: "Plateup - 19:00 Fri",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8182994313033859072/",
        platform: " ",
        description: "The plateup tournament will work in a \"best run wins\" sort of way.</br></br>When the tournament starts, we will publish a seed and recipe that you should use. After that you have 4h to get the best run you possibly can and \"submit\" it in the discord (more details on the day). We will then meet up so each group can present their restaurant and we can crown the winner.</br></br>Teams are 1-2 people, at least one team member has to be level 7.</br></br>If you have any questions, you can reach out on Discord to kydonia or iceplunker.",
        logoPath: "",
      },
      {
        title: "Mario Kart 8 Deluxe 14:30 Sat",
        toornUrl:"https://play.toornament.com/en_GB/tournaments/8153272581437988864/",
        platform: "Nintendo Switch",
        description:"Welcome to this semester's Mario Kart tournament!</br>Are you ready to throw your friends out the map? Race yourself to the top? Then come join us at the Fall 2024 LAN at console corner on 16/11/24 at 14:30!</br></br>We have double elimination again this semester, meaning you will have more than one shot to get a price!</br></br>So grab your friends by the hand, and signup for the tournament!</br></br>As always we will provide soda and possibly some snacks during the tournament, and of course we will bring a lot of fun!",
        logoPath: "",
      },
      {
        title: "Civilization VI 19:00 Fri+Sat",
        toornUrl:"https://play.toornament.com/tournaments/8188128583154884608/" ,
        platform: "PC",
        description:"Are you a fan of strategy games? Do you enjoy making fragile alliances with other players, only to betray them when they are most vulnerable? Do you enjoy pitting the whole world against one player, or in turn having the whole world pitted against you? Well then you'll enjoy this semesters ITU LAN tournament in Sid Meier's Civilization VI.</br></br>The game will be played in two parts, from 19:00 to 23:00 on Friday and Saturday.</br></br>Empires will be drafted on the day of, so unfortunately, little planning before the tournament will be possible.</br></br>Note that the expansions \"Rise and Fall\" and \"Gathering Storm\" are necessary for participation.</br></br>The mod list for the game can be found here: <a href=\"https://steamcommunity.com/sharedfiles/filedetails/?id=3045475898\">here</a>",
        logoPath: "",
      },
      {
        title: "Just Dance 2024 - 12:00 Sat",
        toornUrl:"https://play.toornament.com/tournaments/8261526198452051968/",
        platform: "Nintendo Switch",
        description:"✨ Ready to Dance Your Heart Out? ✨</br></br>🗓 Date: November 16, 2024</br>🕛 Time: 12 PM</br>📍 Location: Console Corner, 1st Floor</br></br>🕺No dance experience? No worries at all! Everyone is welcome to join the fun.</br></br>🏆 This is your chance to dance, laugh and maybe even claim some fantastic rewards! Mark your calendars, bring your friends and let's make this a day to remember. We can't wait to see you there! 🎉💃",
        logoPath: "",
      },
      {
        title: "Escape Room Sat",
        toornUrl:"https://calendly.com/itu-escape/escape-room-booking",
        platform: "IRL",
        description: "🧛‍♂️ Enter if you dare... The Transylvania Escape Room awaits! Step into a dark, eerie castle where vampires lurk in every shadow. 🏰 Feel the chill of ancient secrets and race against the clock to escape Count Dracula's lair and save your friends from becoming vampires before midnight strikes! Can you solve the clues and avoid becoming his next feast? ⚰️</br></br>Gather your bravest friends, and get ready for a spine-chilling adventure. Book your spot for free now if you think you’re up for the challenge... if you dare. 🕯️🔒</br> Sign up link: <a href=\"https://calendly.com/itu-escape/escape-room-booking\">here</a>",
        logoPath: "",
      },
      {
        title: "Heartstone Battlegrounds - 20:00 Sat",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8232306944741605376/",
        platform: "PC",
        description: "Welcome traveler. Welcome to the Tavern, battle it out against 7 other champions to win the top PRIZE!!! </br></br>The format of the game is going to be free for all where the top 4 players of each bracket will go to the next round.</br>The Final top 4 players win prizes.</br></br>Have a fun battling time!!!",
        logoPath: "",
      },
      {
          title: "Beat Saber - 20:00 Sat",
          toornUrl: "https://play.toornament.com/en_GB/tournaments/8232301424174014464/",
          platform: " ",
          description: "Are you an expert at slicing blocks apart with</br>preposterously advanced light saber weaponry at high speed intervals? Just looking for some casual fun with friends? Or feel the need to get in a bit of exercise away from the chosen gaming chair of the weekend?</br></br>Then come and join us Fall 2024 LAN at the second floor in the ITU building (exact location TBA) on the 16th of november at 20:00 / 8PM Saturday, and become the champion amongst the challengers, or go out in the glorious blaze trying!</br></br>Check-in is an hour earlier at 19:00 / 7PM, after which we settle on the final tournament structure, there will be a check-in notification over LAN's Discord.</br></br>Registration is on a first-come-first-served principle.</br>- Sign up early to secure your spot!</br>- Remember to read the rules if you sign up!",
          logoPath: "",
        },
        {
          title: "Tekken 8 - 19:00 Fri ",
          toornUrl: "https://play.toornament.com/en_GB/tournaments/8232297042904719360/",
          platform: " ",
          description: "Welcome to this semester's TEKKEN 8 tournament!</br></br>We have double elimination, meaning you will have more than one shot to get a price!</br></br>So get ready to duke it out with friendly competitors in TEKKEN 8! No previous fighting game experience is required for participation, so grab a friend and join in on the fun!",
          logoPath: "",
        },
        {
          title: "Magic the Gathering Draft - Fri 17:00",
          toornUrl: "https://play.toornament.com/en_GB/tournaments/8188061101903339520/",
          platform: " ",
          description: "Please use your real name when registering!</br></br>Greetings people \\[T]/</br></br>We're doing MTG tournament at ITU LAN yet again.</br></br>More information will follow.",
          logoPath: "",
        },
        {
          title: "Mario Party Minigames FFA - 18:00 Sat",
          toornUrl: "https://play.toornament.com/en_GB/tournaments/8186141666466373632/",
          platform: " ",
          description: "Are you a jack-of-all-trades, and want to show off your varied skills? Or maybe you're here to have a chaotic party? Come and have some fun, where you can play against other players in Mario Party Minigames! And who knows, with the chaos and randomness that is mario party, you might even get to the end and win big! Come and join us Fall 2024 LAN at console corner on 16/11/24",
          logoPath: "",
        },
        {
        title: "Chess by ITU Rooks - 17:00 Sat",
        toornUrl: "https://play.toornament.com/en_GB/tournaments/8183032325901705216/",
        platform: " ",
        description: "Did you miss your chance of winning our last tournament? Or did you just hear about ITU Rooks, and want to join? Either way, welcome to the second chess tournament hosted this year.</br></br>We will be playing swiss-system tournament with 10+0 time-control for each player, giving everyone 6 games to prove their strength.</br></br>Be there or be square!",
        logoPath: "",
        },
      // {
      //   title: "",
      //   toornUrl: "",
      //   platform: " ",
      //   description: "",
      //   logoPath: "",
      // },
    ],
  },
}